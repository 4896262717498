<template>
  <div class="product-gift">
    <div class="product-gift__wrapper">
      <div class="product-gift__img-container">
        <ProductLink :product="product" @click.prevent="onNavigate">
          <img
            class="product-gift__img"
            :src="product.getMainImage('small')"
            :alt="product.getName()"
          />
        </ProductLink>
        <div class="product-gift__label">
          <img src="/icons/gift.svg" alt="product gift" />
        </div>
      </div>
      <div class="product-gift__info">
        <ProductLink
          class="product-gift__title"
          :product="product"
          @click.prevent="onNavigate"
        >
          {{ product.getName() }}
        </ProductLink>
        <div class="product-gift__code">
          {{ _T("@Code") }}
          <span>{{ product.code }}</span>
        </div>
      </div>
    </div>
    <div class="product-gift__footer">
      <p>{{ quantity }} шт.</p>
      <p class="product-gift__price">{{ _T("@Free") }}</p>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
  quantity: { type: Number, required: false, default: 1 },
  closeCart: { type: Function, required: false },
});

const onNavigate = () => {
  if (undefined !== props.closeCart) {
    props.closeCart();
  }
};
</script>

<style scoped lang="scss">
.product-gift {
  width: 95%;

  @include flex-container(row, space-between, center);
  gap: 16px;

  border-radius: 16px;
  background-color: #d0ead366;

  padding: 8px;

  @include mobile {
    flex-direction: column;
    gap: 8px;
  }

  &__wrapper {
    width: 100%;

    @include flex-container(row, flex-start, center);
    gap: 16px;
  }

  &__footer {
    max-width: 240px;
    width: 100%;

    @include flex-container(row, space-evenly, center);
    gap: 16px;

    @include font(18, 24, 700);
  }

  &__img-container {
    position: relative;
  }

  &__img {
    @include fixedHW(44px);
  }

  &__label {
    position: absolute;
    top: -6px;
    right: -12px;

    @include fixedHW(24px);

    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: var(--color-green-700);
  }

  &__info {
    @include flex-container(column, flex-start);
    gap: 4px;
  }

  &__title {
    font-weight: 600;

    transition: color 0.2s;

    @include on-hover {
      color: var(--color-primary-base);
    }

    @include mobile {
      @include font(14, 20, 600);
    }
  }

  &__code {
    @include font(12, 16);
  }

  &__price {
    color: var(--color-green-700);
  }
}
</style>
